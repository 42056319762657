import email from '../assets/email.png'
import cSharp from '../assets/cSharp.png'
import css from '../assets/css.png'
import git from '../assets/git.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import node from '../assets/node.png'
import react from '../assets/react.png'
import typescript from '../assets/typescript.png'
import profile from '../assets/profile.png'
import circle from '../assets/circle.svg'
import logo from '../assets/logo.png'
import einstein from '../assets/einstein.png'
import mongoDb from '../assets/mongoDb.png'


export default {
  einstein,
  email,
  mongoDb,
  cSharp,
  css,
  git,
  html,
  javascript,
  node,
  react,
  typescript,
  profile,
  circle,
  logo,
}