import React from 'react'

let navItems = [
  'home',
  'about',
  'work',
  'skills',
  'testimonials',
  'contact',
]

const NavigationDots = ({ active }) => {
  return (
    <div
      className='app__navigation'
    >
      {navItems.map((x, index) => {
        return (
          <a
            href={`#${x}`}
            key={x + index}
            className="app__navigation-dot"
            style={
              active === x ?
                { backgroundColor: '#000000' } : {}
            }
          ></a>
        )
      })}
    </div>
  )
}

export default NavigationDots