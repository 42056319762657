import React from 'react'
import './Header.scss'
import { AppWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { images } from '../../constants'


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const skillImagesArray = [
  images.cSharp,
  images.react,
  images.typescript,
  images.mongoDb,
  images.node,
]

const Header = () => {

  return (
    <div
      className='app__header app__flex'
    >
      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1]
        }}
        transition={{
          duration: 0.5
        }}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <motion.div
              style={{
                fontSize: '40px',
                marginBottom: '-20px',
                marginRight: '-45px',
                paddingBottom: '20px',
                paddingRight: '45px',
                display: 'inline-block',
              }}
              whileHover={{
                rotate: [20, -20, 20],
                transition: {
                  duration: 0.5,
                  repeat: Infinity,
                }
              }}
            >
              👋
            </motion.div>
            <div
              style={{
                marginLeft: 20
              }}
            >
              <p className='p-text'>Hi, I'm</p>
              <h1 className='head-text'>Christopher</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='h1-text'><b>Technology Leader</b></p>
            <p className='p-text'></p>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Full Stack Software Engineer</p>
            <p className='p-text'></p>
          </div>
        </div>
      </motion.div>
      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1]
        }}
        transition={{
          duration: 0.5,
          delayChildren: 0.5
        }}
        className='app__header-img'
      >
        <img
          src={images.profile}
          alt="profile_bg"
        />
        <motion.img
          whileInView={{
            scale: [0, 1]
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut'
          }}
          src={images.circle}
          alt="profile_circle"
          className='overlay_circle'
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'
      >
        {skillImagesArray.map((circle, index) => (
          <motion.div
            whileHover={{
              scale: 1.25
            }}
            className='circle-cmp app__flex'
            key={`circle-${index}`}>
            <img src={circle} alt='circle' />
          </motion.div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')