import React, { useState } from 'react'
import './Navbar.scss'
import { HiMenuAlt4, HiX } from 'react-icons/hi'
import { motion } from 'framer-motion'
import { images } from '../../constants'


let navItems = [
  'home',
  'about',
  'work',
  'skills',
  'testimonials',
  'contact',
]


const Navbar = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <nav
      className='app__navbar'
    >
      <div
        className='app__navbar-logo'
      >
        <img
          src={images.logo} alt='logo'
        />
      </div>
      <ul
        className='app__navbar-links'
      >
        {navItems.map((x) => {
          return (
            <li
              className='app__flex p-text'
              key={`link-${x}`}>
              <div />
              <a href={`#${x}`}>{x}</a>
            </li>
          )
        })}
      </ul>
      <div
        className='app__navbar-menu'
      >
        <HiMenuAlt4
          onClick={() => setToggle(true)}
        />
        {toggle && (
          <motion.div
            whileInView={{
              x: [300, 0]
            }}
            transition={{
              duration: 0.85,
              ease: 'easeOut'
            }}
          >
            <HiX
              onClick={() => setToggle(false)}
            />
            <ul
              className='app__navbar-links'
            >
              {navItems.map((x) => {
                return (
                  <li
                    key={x}>
                    <a
                      href={`#${x}`}
                      onClick={() => setToggle(false)}
                    >
                      {x}
                    </a>
                  </li>
                )
              })}
            </ul>
          </motion.div>
        )
        }
      </div>

    </nav>
  )
}

export default Navbar